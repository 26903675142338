import MetisSVG from 'assets/images/metis.svg';
export enum CONTRACT_DOMAIN {
    layer1Deposit = 'layer1Deposit',
    // layer1Deposit = 'test-l1-bridge',
    metisOracle = 'metisOracle',
    layer2Withdraw = 'layer2Withdraw',
    metisToken = 'metisToken',
    l2gasoracle = 'l2gasoracle',
}

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export enum ChainId {
    testnet = 11155111,
    layer2Testnet = 59902,
    mainnet = 1,
    layer2Mainnet = 1088,
}

export enum Env {
    test = 'test',
    mainnet = 'mainnet',
}

export enum Layer {
    layer1 = 'layer1',
    layer2 = 'layer2',
}
export const BridgeCostApi = {
    [Env.test]: 'https://api-dac.metis.io/test/service/api/bridge/gasinmetis',
    [Env.mainnet]: 'https://api-dac.metis.io/service/api/bridge/gasinmetis',
};

export const EnvLayerToChainId = {
    [Env.test]: {
        [Layer.layer1]: ChainId.testnet,
        [Layer.layer2]: ChainId.layer2Testnet,
    },
    [Env.mainnet]: {
        [Layer.layer1]: ChainId.mainnet,
        [Layer.layer2]: ChainId.layer2Mainnet,
    },
};

export const DOMAIN_CONFIG = {
    [Env.test]: {
        [Layer.layer1]: {
            [CONTRACT_DOMAIN.metisToken]: {
                domain: 'bridge_metis',
            },
            [CONTRACT_DOMAIN.layer1Deposit]: {
                domain: 'bridge_l1bridge',
            },
            [CONTRACT_DOMAIN.metisOracle]: {
                domain: 'bridge_mvm_l1oracle',
            },
        },
        [Layer.layer2]: {
            [CONTRACT_DOMAIN.metisToken]: {
                domain: 'bridge_metis',
            },
            [CONTRACT_DOMAIN.layer2Withdraw]: {
                domain: 'bridge_l2bridge_v2',
            },
            [CONTRACT_DOMAIN.l2gasoracle]: {
                domain: 'bridge_l2gasoracle',
            },
        },
    },
    [Env.mainnet]: {
        [Layer.layer1]: {
            [CONTRACT_DOMAIN.metisToken]: {
                domain: 'bridge_metis',
            },
            [CONTRACT_DOMAIN.layer1Deposit]: {
                domain: 'bridge_l1bridge',
            },
            [CONTRACT_DOMAIN.metisOracle]: {
                domain: 'bridge_mvm_l1oracle',
            },
        },
        [Layer.layer2]: {
            [CONTRACT_DOMAIN.metisToken]: {
                domain: 'bridge_metis',
            },
            [CONTRACT_DOMAIN.layer2Withdraw]: {
                domain: 'bridge_l2bridge_v2',
            },
            [CONTRACT_DOMAIN.l2gasoracle]: {
                domain: 'bridge_l2gasoracle',
            },
        },
    },
};

export const NETWORK_CONFIG = {
    [Env.test]: {
        [Layer.layer1]: {
            name: 'Sepolia',
            scanLable: 'Sepolia Scan',
            explore: 'https://sepolia.beaconcha.in/',
        },
        [Layer.layer2]: {
            name: 'Metis(Sepolia)',
            scanLable: 'Metis(Sepolia)-explorer',
            explore: 'https://sepolia-explorer.metisdevops.link/',
        },
    },
    [Env.mainnet]: {
        [Layer.layer1]: {
            name: 'Ethereum',
            scanLable: 'Etherscan',
            explore: 'https://etherscan.io/',
        },
        [Layer.layer2]: {
            name: 'Andromeda',
            scanLable: 'Andromeda-explorer',
            explore: 'https://andromeda-explorer.metis.io/',
        },
    },
};

export enum TxActStatus {
    SUCCESS = 'SUCCESS',
}

export enum TxStatus {
    SUCCESS = 'SUCCESS',
}

export const GAS_LIMIT = 0.1;

export enum Token {
    metis = 'metis',
    eth = 'eth',
    usdc = 'usdc',
    usdt = 'usdt',
    wow = 'wow',
    link = 'link',
    aave = 'aave',
    sushi = 'sushi',
    // crv = 'crv',
    dai = 'dai',
    // uni = 'uni',
    wbtc = 'wbtc',
    busd = 'busd',
    weth = 'weth',
    wsteth = 'wsteth',
}

export const TokenConfig = {
    [Token.eth]: {
        domain: 'bridge_l2_eth',
        img: require('../assets/images/eth_logo.png'),
        [Layer.layer1]: {
            name: 'ETH',
            symbol: 'ETH',
            [Env.mainnet]: {
                address: '',
            },
            [Env.test]: {
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'ETH',
            symbol: 'ETH',
            [Env.mainnet]: {
                address: '0x420000000000000000000000000000000000000A',
            },
            [Env.test]: {
                address: '0x420000000000000000000000000000000000000A',
            },
        },
    },
    [Token.weth]: {
        domain: 'weth',
        img: require('../assets/images/eth_logo.png'),
        [Layer.layer1]: {
            name: 'WETH',
            symbol: 'WETH',
            [Env.mainnet]: {
                address: '',
            },
            [Env.test]: {
                address: '0x0274c35B08310e30Cb206E0cC2374929B05BACd6',
            },
        },
        [Layer.layer2]: {
            name: 'WETH',
            symbol: 'WETH',
            [Env.mainnet]: {
                address: '',
            },
            [Env.test]: {
                address: '0xD08a2917653d4E460893203471f0000826fb4034',
            },
        },
    },
    [Token.metis]: {
        domain: 'bridge_metis',
        img: MetisSVG,
        [Layer.layer1]: {
            name: 'Metis Token',
            symbol: 'METIS',
            [Env.mainnet]: {
                address: '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
            },
            [Env.test]: {
                address: '0x7f49160EB9BB068101d445fe77E17ecDb37D0B47',
            },
        },
        [Layer.layer2]: {
            name: 'Metis Token',
            symbol: 'METIS',
            [Env.mainnet]: {
                address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            },
            [Env.test]: {
                address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            },
        },
    },
    [Token.usdc]: {
        domain: 'usdc_token',
        img: require('../assets/images/usdc_logo.png'),
        [Layer.layer1]: {
            name: 'USDC Token',
            symbol: 'USDC',
            [Env.mainnet]: {
                address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            },
            [Env.test]: {
                address: '0x688334e307A93d08D89aa87c2A636B8DddeF4057',
            },
        },
        [Layer.layer2]: {
            name: 'USDC Token',
            symbol: 'm.USDC',
            [Env.mainnet]: {
                address: '0xea32a96608495e54156ae48931a7c20f0dcc1a21',
            },
            [Env.test]: {
                address: '0xb1d8270f19351328CF3DC4FC9FF3c75d5c770Ca4',
            },
        },
    },
    [Token.usdt]: {
        domain: 'usdt_token',
        img: require('../assets/images/usdt_logo.png'),
        [Layer.layer1]: {
            name: 'USDT Token',
            symbol: 'USDT',
            [Env.mainnet]: {
                address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            },
            [Env.test]: {
                address: '0x899D538ef848B841F55c496670d74b42284EFa92',
            },
        },
        [Layer.layer2]: {
            name: 'USDT Token',
            symbol: 'm.USDT',
            [Env.mainnet]: {
                address: '0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc',
            },
            [Env.test]: {
                address: '0xf8F06e6fdb132D99BD8f91b54dBFe77073dA5bcC',
            },
        },
    },
    [Token.wow]: {
        domain: 'm.wow',
        img: require('../assets/images/wow_logo.png'),
        [Layer.layer1]: {
            name: 'WOW Token',
            symbol: 'WOW',
            [Env.mainnet]: {
                address: '0x3405a1bd46b85c5c029483fbecf2f3e611026e45',
            },
            [Env.test]: {
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'WOW Token',
            symbol: 'm.WOW',
            [Env.mainnet]: {
                address: '0x5ce34d9abe4bf239cbc08b89287c87f4cd6d80b7',
            },
            [Env.test]: {
                address: '',
            },
        },
    },
    [Token.link]: {
        domain: 'm.link',
        img: require('../assets/images/link_logo.png'),
        [Layer.layer1]: {
            name: 'LINK Token',
            symbol: 'LINK',
            [Env.mainnet]: {
                address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
            },
            [Env.test]: {
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'LINK Token',
            symbol: 'm.LINK',
            [Env.mainnet]: {
                address: '0xf5f66d5daa89c090a7afa10e6c1553b2887a9a33',
            },
            [Env.test]: {
                address: '',
            },
        },
    },
    [Token.aave]: {
        domain: 'aave_token',
        img: require('../assets/images/aave_logo.png'),
        [Layer.layer1]: {
            name: 'AAVE Token',
            symbol: 'AAVE',
            [Env.mainnet]: {
                address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
            },
            [Env.test]: {
                address: '0x3b7A1f1Ff740381F723eBfd4d5237850e4c0Ece7',
            },
        },
        [Layer.layer2]: {
            name: 'AAVE Token',
            symbol: 'm.AAVE',
            [Env.mainnet]: {
                address: '0xd1f0a4e5444eed0fbcd6624dcef7ef33043e6168',
            },
            [Env.test]: {
                address: '0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0',
            },
        },
    },
    // [Token.crv]: {
    //     domain: 'm.crv',
    //     img: require('../assets/images/crv_logo.png'),
    //     [Layer.layer1]: {
    //         name: 'CRV Token',
    //         symbol: 'CRV',
    //     },
    //     [Layer.layer2]: {
    //         name: 'CRV Token',
    //         symbol: 'm.CRV',
    //     },
    // },
    [Token.dai]: {
        domain: 'dai_token',
        img: require('../assets/images/dai_logo.png'),
        [Layer.layer1]: {
            name: 'DAI Token',
            symbol: 'DAI',
            [Env.mainnet]: {
                address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
            },
            [Env.test]: {
                address: '0x1Dd098A49B495edFD804F1114eC3D5B683D425Da',
            },
        },
        [Layer.layer2]: {
            name: 'DAI Token',
            symbol: 'm.DAI',
            [Env.mainnet]: {
                address: '0x4c078361fc9bbb78df910800a991c7c3dd2f6ce0',
            },
            [Env.test]: {
                address: '0xfB24B51BA6F5A742Df226711071B7D8c20F942C0',
            },
        },
    },

    [Token.sushi]: {
        domain: 'sushi',
        img: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/SUSHI/logo.png',
        [Layer.layer1]: {
            name: 'Sushi Token',
            symbol: 'Sushi',
            [Env.mainnet]: {
                address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
            },
            [Env.test]: {
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'Sushi Token',
            symbol: 'm.SUSHI',
            [Env.mainnet]: {
                address: '0x17ee7e4da37b01fc1bcc908fa63df343f23b4b7c',
            },
            [Env.test]: {
                address: '',
            },
        },
    },
    [Token.busd]: {
        domain: 'busd',
        img: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/BUSD/logo.png',
        [Layer.layer1]: {
            name: 'BUSD Token',
            symbol: 'BUSD',
            [Env.mainnet]: {
                address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
            },
            [Env.test]: {
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'BUSD Token',
            symbol: 'm.BUSD',
            [Env.mainnet]: {
                address: '0xb809cda0c2f79f43248c32b5dcb09d5cd26bbf10',
            },
            [Env.test]: {
                address: '',
            },
        },
    },

    // [Token.uni]: {
    //     domain: 'm.uni',
    //     img: require('../assets/images/uni_logo.png'),
    //     [Layer.layer1]: {
    //         name: 'Uniswap Token',
    //         symbol: 'UIN',
    //     },
    //     [Layer.layer2]: {
    //         name: 'UIN Token',
    //         symbol: 'm.UIN',
    //     },
    // },
    [Token.wbtc]: {
        domain: 'wbtc',
        img: require('../assets/images/wbtc_logo.png'),
        [Layer.layer1]: {
            name: 'WBTC Token',
            symbol: 'WBTC',
            [Env.mainnet]: {
                address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
            },
            [Env.test]: {
                address: '0xfdb2b2E281Df8C49e2d643679243815F5d1d08Ab',
            },
        },
        [Layer.layer2]: {
            name: 'WBTC Token',
            symbol: 'm.WBTC',
            [Env.mainnet]: {
                address: '0x433e43047b95cb83517abd7c9978bdf7005e9938',
            },
            [Env.test]: {
                address: '0x31D7095Ff509272D28D7Fb3aFEE1EFFf2E61fA43',
            },
        },
    },
    [Token.wsteth]: {
        domain: 'wsteth',
        img: require('../assets/images/wsteth.webp'),
        [Layer.layer1]: {
            name: 'Wrapped liquid staked Ether',
            symbol: 'wstETH',
            [Env.mainnet]: {
                address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
            },
            [Env.test]: {
                // address: '0x7f49160EB9BB068101d445fe77E17ecDb37D0B47',
                address: '',
            },
        },
        [Layer.layer2]: {
            name: 'Wrapped liquid staked Ether',
            symbol: 'wstETH',
            [Env.mainnet]: {
                address: '0x84a600039E7C1169dA4aF41513763883D583fcC3',
            },
            [Env.test]: {
                // address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
                address: '',
            },
        },
    },
};

export const LidoTokenList = [Token.wsteth];

export const TokenList = [
    Token.metis,
    Token.eth,
    Token.wbtc,
    Token.usdc,
    ...LidoTokenList,
    Token.usdt,
    Token.dai,
    Token.busd,
    Token.aave,
    // Token.crv,
    Token.link,
    // Token.uni,
    Token.sushi,
    Token.wow,
];

export const TestnetTokenList = [Token.metis, Token.eth];

export const SWITCH_NETWORK_CONFIG = {
    [ChainId.layer2Testnet]: {
        chainId: `0x${ChainId.layer2Testnet.toString(16)}`,
        chainName: 'Metis Sepolia',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [
            'https://sepolia.metisdevops.link',
            // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
        ],
        blockExplorerUrls: ['https://sepolia-explorer.metisdevops.link'],
    },
    [ChainId.layer2Mainnet]: {
        chainId: `0x${ChainId.layer2Mainnet.toString(16)}`,
        chainName: 'Andromeda',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [`https://andromeda.metis.io/?owner=${ChainId.layer2Mainnet}`],
        blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
    },
    [ChainId.testnet]: {
        chainId: `0x${ChainId.testnet.toString(16)}`,
        chainName: 'Sepolia',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: [`https://rpc.sepolia.org`],
        blockExplorerUrls: ['https://sepolia.etherscan.io'],
    },
    [ChainId.mainnet]: {
        chainId: `0x${ChainId.mainnet.toString(16)}`,
        chainName: 'Ethereum',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: [
            `https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
        ],
        blockExplorerUrls: ['https://etherscan.io'],
    },
};

export const RPC = {
    [Env.mainnet]: {
        [Layer.layer1]:
            'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        [Layer.layer2]: 'https://andromeda-rpc.metis.io',
    },
    [Env.test]: {
        [Layer.layer1]: 'https://rpc.sepolia.org',
        [Layer.layer2]: 'https://sepolia.metisdevops.link',
    },
};
