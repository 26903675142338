import { ChainId } from '../constants';
import { Config as DepositConfig } from 'contracts/l1bridge';
import { Config as WithdrawConfig } from 'contracts/l2bridge-v2';
import { Config as LidoDepositConfig } from 'contracts/LidoDepositBridge';
import { Config as LidoWithdrawBridge } from 'contracts/LidoWithdrawBridge';

export function getBridgeSpenderByChainId(chainId: ChainId) {
    const config = {
        ...DepositConfig,
        ...WithdrawConfig,
    };

    return config[chainId];
}

export function getLidoBridgeSpenderByChainId(chainId: ChainId) {
    const config = {
        ...LidoDepositConfig,
        ...LidoWithdrawBridge,
    };

    return config[chainId];
}
