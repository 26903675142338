import ABI from './abi.json';
import { ChainId } from '../../constants';

export const Config = {
    [ChainId.mainnet]: {
        address: '0x2b329D37FcBCBf22b9B0bB473789fC27EB28494B',
        abi: ABI,
    },
    [ChainId.testnet]: {
        // address: '0x9848dE505e6Aa301cEecfCf23A0a150140fc996e',
        address: '',
        abi: ABI,
    },
};
