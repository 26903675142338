import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'state/hooks';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

import {
    CONTRACT_DOMAIN,
    DOMAIN_CONFIG,
    TxActStatus,
    TxStatus,
    Layer,
    EnvLayerToChainId,
    BridgeCostApi,
    Token,
    TokenConfig,
    Env,
    ChainId,
} from '../constants';
import ModalFunc from 'utils/components/modal';
import axios from 'axios';
import Alert from 'utils/components/alert';
import {
    getContractByEnvLayer,
    useGetContractByUserWeb3,
} from 'hooks/use-contract';
import { Config as L1GasOracleConfig } from 'contracts/l1oracle';
import { Config as L2GasOracleConfig } from 'contracts/l2gasoracle';
import {
    getBridgeSpenderByChainId,
    getLidoBridgeSpenderByChainId,
} from 'contracts/utils';
import { getPolisClient } from 'utils';
function handleError(e: any) {
    if (e) {
        let errorMessage = e.message;
        if (e.code === 10042) {
            errorMessage = 'Insufficient funds';
        }

        if (e.code === 1000) {
            return;
        }

        Alert({
            type: 'error',
            text: errorMessage || 'Someting wrong, please try again later',
        });
    }
}

interface ServerGasEstimate {
    l1: BigNumber;
    l2: BigNumber;
}

interface PolisParams {
    domain: string;
    chainId: number;
    method: string;
    params: any[];
    tooltips: boolean;
    extends: any;
}

interface LidoBridgeParams {
    method: string;
    params: any[];
    tooltips: boolean;
    extends: any;
}

export default function useMiddleareClient() {
    const user = useAppSelector((state) => state.user);
    const { isPolis, web3, chainId } = user;
    const { client, env, layer } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const { tokens } = useAppSelector((state) => state.tokens);
    const getContractByUserWeb3 = useGetContractByUserWeb3();
    const [bridgeContract, setBridgeContract] = useState<any>(undefined);
    const [liBridgeContract, setLidiBridgeContract] = useState<any>(undefined);

    useEffect(() => {
        if (!isPolis && web3 && chainId) {
            const config = getBridgeSpenderByChainId(chainId);
            if (config) {
                const contractInstance = getContractByUserWeb3(
                    config.address,
                    config.abi,
                );
                setBridgeContract(contractInstance);
            }

            const lidoConfig = getLidoBridgeSpenderByChainId(chainId);
            if (config) {
                const contractInstance = getContractByUserWeb3(
                    lidoConfig.address,
                    lidoConfig.abi,
                );
                setLidiBridgeContract(contractInstance);
            }
        }
    }, [isPolis, web3, chainId]);

    const layer1EthBalance = useMemo(() => {
        return tokens[Token.eth].layer1.balance;
    }, [tokens[Token.eth]]);

    const layer2MetisBalance = useMemo(() => {
        return tokens[Token.metis].layer2.balance;
    }, [tokens[Token.metis]]);

    async function getGasEstimateFromServer(): Promise<
        ServerGasEstimate | undefined
    > {
        // const api = BridgeCostApi[env];
        // try {
        //     const res = await axios.get(api);
        //     if (res && res.data && res.data.data) {
        //         return {
        //             l1: new BigNumber(res.data.data.l1),
        //             l2: new BigNumber(res.data.data.l2),
        //         };
        //     }
        // } catch (e) {
        //     console.error(api);
        //     console.error(e);
        //     Alert({
        //         type: 'error',
        //         text: 'gas estimate server error ',
        //     });
        // }

        return Promise.resolve({
            l1: new BigNumber(0.5).shiftedBy(18),
            l2: new BigNumber(0),
        });
    }

    async function getL2GasFromMetisOracle() {
        try {
            if (isPolis) {
                if (client) {
                    const minL2GasRes = await client.sendTxAsync(
                        DOMAIN_CONFIG[env][Layer.layer1][
                            CONTRACT_DOMAIN.metisOracle
                        ].domain,
                        EnvLayerToChainId[env][Layer.layer1],
                        'minL2Gas',
                        [],
                        true,
                    );

                    if (
                        minL2GasRes &&
                        minL2GasRes.act === TxActStatus.SUCCESS &&
                        minL2GasRes.result
                    ) {
                        return new BigNumber(minL2GasRes.result);
                    }
                }
            } else {
                const config =
                    L1GasOracleConfig[
                        env === Env.mainnet ? ChainId.mainnet : ChainId.testnet
                    ];
                const contract = getContractByEnvLayer(
                    config.address,
                    config.abi,
                    env,
                    layer,
                );

                if (contract) {
                    const minL2GasRes = await contract.methods
                        .minL2Gas()
                        .call();

                    if (minL2GasRes) {
                        return new BigNumber(minL2GasRes);
                    }
                }
            }
        } catch (e) {
            console.error('getL2Gas func error', e);
        }

        return undefined;
    }

    async function getOracleDiscount() {
        try {
            if (isPolis) {
                if (client) {
                    const res = await client.sendTxAsync(
                        DOMAIN_CONFIG[env][Layer.layer1][
                            CONTRACT_DOMAIN.metisOracle
                        ].domain,
                        EnvLayerToChainId[env][Layer.layer1],
                        'discount',
                        [],
                        true,
                    );
                    if (res && res.result) {
                        return new BigNumber(res.result);
                    }
                }
            } else {
                const config =
                    L1GasOracleConfig[
                        env === Env.mainnet ? ChainId.mainnet : ChainId.testnet
                    ];
                const contract = getContractByEnvLayer(
                    config.address,
                    config.abi,
                    env,
                    layer,
                );

                if (contract) {
                    const res = await contract.methods.discount().call();

                    if (res) {
                        return new BigNumber(res);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            console.error(`getOracleDiscount error`);
        }
        return undefined;
    }

    async function getLayer2GasOracleminL1GasLimit() {
        try {
            if (isPolis) {
                if (client) {
                    const res = await client.sendTxAsync(
                        DOMAIN_CONFIG[env][Layer.layer2][
                            CONTRACT_DOMAIN.l2gasoracle
                        ].domain,
                        EnvLayerToChainId[env][Layer.layer2],
                        'minErc20BridgeCost',
                        [],
                        true,
                    );
                    if (res && res.result) {
                        return new BigNumber(res.result);
                    }
                }
            } else {
                const config =
                    L2GasOracleConfig[
                        env === Env.mainnet
                            ? ChainId.layer2Mainnet
                            : ChainId.layer2Testnet
                    ];
                const contract = getContractByEnvLayer(
                    config.address,
                    config.abi,
                    env,
                    layer,
                );

                if (contract) {
                    const minL2GasRes = await contract.methods
                        .minErc20BridgeCost()
                        .call();

                    if (minL2GasRes) {
                        return new BigNumber(minL2GasRes);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            console.error('getLayer2GasOracleminL1GasLimit');
        }
        return undefined;
    }

    async function getLayer2TransferAtLeaseTokenAmount() {
        const estimateRes = await getGasEstimateFromServer();

        let l1gasEstimate;
        if (estimateRes) {
            l1gasEstimate = estimateRes.l1;
        }

        const minL1GasLimit = await getLayer2GasOracleminL1GasLimit();

        console.log(
            'estimateResFromServerL1',
            l1gasEstimate ? l1gasEstimate.toFixed() : 'null',
        );
        console.log(
            'minL1GasLimit',
            minL1GasLimit ? minL1GasLimit.toFixed() : 'null',
        );
        if (l1gasEstimate && minL1GasLimit) {
            const minGas =
                minL1GasLimit && l1gasEstimate.isGreaterThan(l1gasEstimate)
                    ? minL1GasLimit
                    : l1gasEstimate;

            return minGas;
        }

        return undefined;
    }

    async function getBridgeTokenParams(
        layer: Layer,
        receivingAddress: string,
        amount: BigNumber,
        tokenName: Token,
    ): Promise<PolisParams | undefined> {
        if (user.address) {
            try {
                const isLayer1Eth =
                    tokenName === Token.eth && layer === Layer.layer1;
                if (layer === Layer.layer1) {
                    const contract =
                        DOMAIN_CONFIG[env][Layer.layer1][
                            CONTRACT_DOMAIN.layer1Deposit
                        ];
                    if (contract) {
                        let tokenAddressFrom = '';
                        let tokenAddressTo = '';

                        if (!isLayer1Eth) {
                            if (isPolis && client) {
                                const tokenAddressFromRes =
                                    await client.getDomain(
                                        TokenConfig[tokenName].domain,
                                        `${EnvLayerToChainId[env][layer]}`,
                                    );

                                if (
                                    tokenAddressFromRes &&
                                    tokenAddressFromRes.contract_address
                                ) {
                                    tokenAddressFrom =
                                        tokenAddressFromRes.contract_address;
                                }
                            } else {
                                tokenAddressFrom =
                                    TokenConfig[tokenName][layer][env].address;
                            }
                        }

                        if (layer === Layer.layer1) {
                            if (isPolis && client) {
                                const tokenAddressToRes =
                                    await client.getDomain(
                                        TokenConfig[tokenName].domain,
                                        `${
                                            EnvLayerToChainId[env][Layer.layer2]
                                        }`,
                                    );

                                if (
                                    tokenAddressToRes &&
                                    tokenAddressToRes.contract_address
                                ) {
                                    tokenAddressTo =
                                        tokenAddressToRes.contract_address;
                                }
                            } else {
                                tokenAddressTo =
                                    TokenConfig[tokenName][
                                        layer === Layer.layer1
                                            ? Layer.layer2
                                            : Layer.layer1
                                    ][env].address;
                            }
                        }
                        if (
                            !isLayer1Eth &&
                            (!tokenAddressFrom || !tokenAddressTo)
                        ) {
                            console.error(
                                `Token ${tokenName}, layer1:${tokenAddressFrom}, layer2: ${tokenAddressTo} address error`,
                            );
                        }
                        const estimateRes = await getGasEstimateFromServer();
                        const l2gasRes = await getL2GasFromMetisOracle();

                        console.log(
                            'estimateResFromServerL2',
                            estimateRes ? estimateRes.l2.toFixed() : 'null',
                        );
                        console.log(
                            'l2gasRes',
                            l2gasRes ? l2gasRes.toFixed() : 'null',
                        );
                        if (estimateRes && l2gasRes) {
                            const gasParams = l2gasRes.isGreaterThan(
                                estimateRes.l2,
                            )
                                ? l2gasRes
                                : estimateRes.l2;
                            const discount = await getOracleDiscount();
                            console.log(
                                'discount',
                                discount ? discount.toFixed() : 'null',
                            );
                            if (discount) {
                                if (tokenName === Token.eth) {
                                    console.log(
                                        'eth value',
                                        amount
                                            .shiftedBy(18)
                                            .integerValue()
                                            .toFixed(),
                                    );
                                    const isOverBalance = gasParams
                                        .multipliedBy(discount)
                                        .plus(amount.shiftedBy(18))
                                        .isGreaterThan(
                                            layer1EthBalance.shiftedBy(18),
                                        );
                                    return {
                                        domain: contract.domain,
                                        chainId:
                                            EnvLayerToChainId[env][
                                                Layer.layer1
                                            ],
                                        method: 'depositETHToByChainId',
                                        params: [
                                            EnvLayerToChainId[env][
                                                Layer.layer2
                                            ],
                                            receivingAddress,
                                            gasParams.toFixed(),
                                            '0x00',
                                        ],
                                        tooltips: true,
                                        extends: {
                                            // value: (isOverBalance
                                            //     ? layer1EthBalance.shiftedBy(18)
                                            //     : gasParams
                                            //           .multipliedBy(discount)
                                            //           .plus(
                                            //               amount.shiftedBy(18),
                                            //           )
                                            // )
                                            //     .integerValue()
                                            //     .toFixed(),
                                            value: amount
                                                .shiftedBy(18)
                                                .integerValue()
                                                .toFixed(),
                                        },
                                    };
                                } else {
                                    return {
                                        domain: contract.domain,
                                        chainId:
                                            EnvLayerToChainId[env][
                                                Layer.layer1
                                            ],
                                        method: 'depositERC20ToByChainId',
                                        params: [
                                            EnvLayerToChainId[env][
                                                Layer.layer2
                                            ],
                                            // L1 metis token address
                                            tokenAddressFrom,
                                            // L2 metis token address, enter any address is fine
                                            tokenAddressTo,
                                            receivingAddress,
                                            amount
                                                .shiftedBy(
                                                    tokens[tokenName][layer]
                                                        .decimals,
                                                )
                                                .toFixed(),
                                            gasParams.toFixed(),
                                            '0x',
                                        ],
                                        tooltips: true,
                                        extends: {
                                            // value: gasParams
                                            //     .multipliedBy(discount)
                                            //     .integerValue()
                                            //     .toFixed(),
                                            value: '0',
                                        },
                                    };
                                }
                            }
                        }
                    }
                } else {
                    const minGas = await getLayer2TransferAtLeaseTokenAmount();

                    if (minGas) {
                        const contract =
                            DOMAIN_CONFIG[env][Layer.layer2][
                                CONTRACT_DOMAIN.layer2Withdraw
                            ];

                        if (tokenName === Token.metis) {
                            return {
                                domain: contract.domain,
                                chainId: EnvLayerToChainId[env][Layer.layer2],
                                method: 'withdrawMetisTo',
                                params: [
                                    receivingAddress,
                                    amount
                                        .shiftedBy(
                                            tokens[tokenName][layer].decimals,
                                        )
                                        .toFixed(),
                                    '0',
                                    '0x',
                                ],
                                tooltips: true,
                                extends: {
                                    value: minGas.toFixed(),
                                },
                            };
                        } else {
                            let l2TokenAddress = '';
                            if (isPolis && client) {
                                const l2TokenAddressRes =
                                    await client.getDomain(
                                        TokenConfig[tokenName].domain,
                                        `${EnvLayerToChainId[env][layer]}`,
                                    );

                                if (
                                    l2TokenAddressRes &&
                                    l2TokenAddressRes.contract_address
                                ) {
                                    l2TokenAddress =
                                        l2TokenAddressRes.contract_address;
                                }
                            } else {
                                l2TokenAddress =
                                    TokenConfig[tokenName][layer][env].address;
                            }

                            if (l2TokenAddress) {
                                return {
                                    domain: contract.domain,
                                    chainId:
                                        EnvLayerToChainId[env][Layer.layer2],
                                    method: 'withdrawTo',
                                    params: [
                                        l2TokenAddress,
                                        receivingAddress,
                                        amount
                                            .shiftedBy(
                                                tokens[tokenName][layer]
                                                    .decimals,
                                            )
                                            .toFixed(),
                                        '0',
                                        '0x',
                                    ],
                                    tooltips: true,
                                    extends: {
                                        value: minGas.toFixed(),
                                    },
                                };
                            } else {
                                console.error(
                                    `Token ${tokenName}, layer2 getDomain return empty address`,
                                );
                            }
                        }
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('getBridgeTokenParams error');
                handleError(e);
            }
        }

        return undefined;
    }

    async function estimateBridgeTokenGas(
        layer: Layer,
        amount: BigNumber,
        tokenName: Token,
        params: PolisParams,
    ) {
        if (user.address && params) {
            try {
                // make sure layer2 metis have enouge metis token to transfer
                // if not add this logic, estimateGas will throw burn amount exceeds balance error
                if (tokenName === Token.metis && layer === Layer.layer2) {
                    const value = new BigNumber(params.extends.value).shiftedBy(
                        -18,
                    );

                    // l2 services fee is greater than l2 metis balance
                    if (value.isGreaterThan(layer2MetisBalance)) {
                        return {
                            fee_num: 0,
                            estimateRemainingMainCoin: value,
                        };
                    } else if (
                        value
                            .plus(amount)
                            .plus(0.1)
                            .isGreaterThan(layer2MetisBalance)
                    ) {
                        // max logic
                        params.params[1] = layer2MetisBalance
                            .minus(value)
                            .shiftedBy(18)
                            .integerValue()
                            .toFixed();
                    }
                }

                let transFee = 0;

                if (isPolis && client) {
                    const estimateGasRes = await client.estimateGasAsync(
                        params.domain,
                        params.chainId,
                        params.method,
                        params.params,
                        true,
                        params.extends,
                    );

                    if (estimateGasRes && estimateGasRes.fee_num) {
                        transFee = estimateGasRes.fee_num;
                    }
                } else if (bridgeContract) {
                    const res = await bridgeContract.methods[params.method](
                        ...params.params,
                    ).estimateGas({
                        from: user.address,
                        value: params.extends.value,
                    });
                    //here
                    if (res) {
                        const gasPrice = await user.web3.eth.getGasPrice();
                        transFee = new BigNumber(res)
                            .multipliedBy(gasPrice)
                            .shiftedBy(-18)
                            .toNumber();
                    }
                }
                let estimateRemainingMainCoin = new BigNumber(
                    params.extends.value,
                )
                    .shiftedBy(-18)
                    .plus(transFee);
                if (layer === Layer.layer1 && tokenName == Token.eth) {
                    estimateRemainingMainCoin =
                        estimateRemainingMainCoin.minus(amount);
                }
                return {
                    fee_num: transFee,
                    // layer1 transfer use eth value to transfer, services fee = 0
                    servicesFee:
                        layer === Layer.layer1 && tokenName === Token.eth
                            ? 0
                            : params.extends.value
                            ? new BigNumber(params.extends.value)
                                  .shiftedBy(-18)
                                  .toNumber()
                            : 0,
                    estimateRemainingMainCoin,
                };
            } catch (e) {
                console.error('estimateBridgeTokenGas error');
                console.error(e);
            }
        }

        return undefined;
    }

    async function bridgeToken(
        layer: Layer,
        receivingAddress: string,
        amount: BigNumber,
        tokenName: Token,
    ) {
        if (user.address) {
            const params = await getBridgeTokenParams(
                layer,
                receivingAddress,
                amount,
                tokenName,
            );
            console.log('bridgeParams');
            console.log(params);
            // gas logic

            if (params) {
                try {
                    const estimateGas = await estimateBridgeTokenGas(
                        layer,
                        amount,
                        tokenName,
                        params,
                    );

                    console.log('estimateGas', estimateGas);

                    if (layer === Layer.layer1) {
                        if (
                            (estimateGas &&
                                estimateGas.estimateRemainingMainCoin.toNumber() &&
                                estimateGas.estimateRemainingMainCoin
                                    .plus(tokenName === Token.eth ? amount : 0)
                                    .isGreaterThan(layer1EthBalance)) ||
                            layer1EthBalance
                                .shiftedBy(18)
                                .isLessThan(params.extends.value)
                        ) {
                            ModalFunc({
                                type: 'sorry',
                                title: 'Sorry',
                                text: `The current ETH Token balance ${layer1EthBalance.toFixed(
                                    3,
                                )} on your account is not sufficient to support this execution. Please leave at least ${(estimateGas
                                    ? estimateGas.estimateRemainingMainCoin.plus(
                                          estimateGas.fee_num,
                                      )
                                    : new BigNumber(
                                          params.extends.value,
                                      ).shiftedBy(-18)
                                ).toFixed(6)} ETH to support this execution`,
                                buttonText: 'OK',
                            });
                            return false;
                        }
                    } else {
                        if (
                            (estimateGas &&
                                estimateGas.estimateRemainingMainCoin.toNumber() &&
                                estimateGas.estimateRemainingMainCoin
                                    .plus(
                                        tokenName === Token.metis ? amount : 0,
                                    )
                                    .isGreaterThan(layer2MetisBalance)) ||
                            layer2MetisBalance
                                .shiftedBy(18)
                                .isLessThan(params.extends.value)
                        ) {
                            ModalFunc({
                                type: 'sorry',
                                title: 'Sorry',
                                text: `The current Metis Token balance ${layer2MetisBalance.toFixed(
                                    3,
                                )} on your account is not sufficient to support this execution. Please leave at least ${(estimateGas
                                    ? estimateGas.estimateRemainingMainCoin.plus(
                                          estimateGas.fee_num,
                                      )
                                    : new BigNumber(
                                          params.extends.value,
                                      ).shiftedBy(-18)
                                ).toFixed(6)} METIS to support this execution`,
                                buttonText: 'OK',
                            });
                            return false;
                        }
                    }

                    if (isPolis && client) {
                        const res = await client.sendTxAsync(
                            params.domain,
                            params.chainId,
                            params.method,
                            params.params,
                            params.tooltips,
                            params.extends,
                        );
                        if (res && res.status === TxStatus.SUCCESS) {
                            return res.data.tx;
                        }
                    } else if (bridgeContract) {
                        const block = await web3.eth.getBlock('latest');
                        console.log('latest block gas used', block.gasUsed);
                        console.log('bridgeContract', bridgeContract._address);
                        console.log('params', params.params);
                        const res = await new Promise((resolve, reject) => {
                            bridgeContract.methods[params.method](
                                ...params.params,
                            )
                                .send({
                                    from: user.address,
                                    value: params.extends.value,
                                    // gasLimit: Math.floor(block.gasUsed * 1.2),
                                    gasLimit:
                                        layer === Layer.layer1
                                            ? 400000
                                            : 2000000,
                                })
                                .on('receipt', function (receipt: any) {
                                    resolve(receipt.transactionHash);
                                })
                                .on('error', function (e: any) {
                                    reject(e);
                                });
                        });

                        return res;
                    }
                } catch (e) {
                    console.error(e);
                    console.error('bridgeToken error');
                    handleError(e);
                }
            } else {
                console.log('getBridgeTokenParams empty error');
            }
        }

        return false;
    }

    async function getBlockNumberFromHash(layer: Layer, tx: string) {
        try {
            if (isPolis) {
                // const txRes = await client.getTxLogsAsync({
                //     chainid: EnvLayerToChainId[env][layer],
                //     txhash: tx,
                // });

                // if (txRes && txRes.length) {
                //     return Number(txRes[0].blockNumber);
                // }
                const polisClient = await getPolisClient(
                    EnvLayerToChainId[env][layer],
                );
                const txRes = await polisClient.web3Provider.getTransaction(tx);

                if (txRes && txRes.blockNumber) {
                    return Number(txRes.blockNumber);
                }
            } else if (user.web3) {
                const res = await user.web3.eth.getTransaction(tx);
                if (res && res.blockNumber) {
                    return Number(res.blockNumber);
                }
            }
        } catch (e) {
            console.error(e);
            console.error(`getBlockNumberFromHash: ${layer} ${tx} ERROR`);
        }
        return undefined;
    }

    async function getCurrnetBlockNumber(layer: Layer) {
        try {
            if (isPolis && client) {
                const res: any = await client.providerCall({
                    method: 'get_block_number', //method name
                    chainid: EnvLayerToChainId[env][layer],
                });

                if (res && res.result) {
                    return Number(res.result);
                }
            } else if (user.web3) {
                const res = await user.web3.eth.getBlockNumber();
                if (res) {
                    return Number(res);
                }
            }
        } catch (e) {
            console.error(e);
            console.error('getCurrnetBlockNumber error');
        }

        return undefined;
    }

    async function getLidoBridgeParams(
        layer: Layer,
        receivingAddress: string,
        amount: BigNumber,
        tokenName: Token,
    ): Promise<LidoBridgeParams | undefined> {
        if (user.address) {
            try {
                if (layer === Layer.layer1) {
                    const tokenAddressFrom =
                        TokenConfig[tokenName][layer][env].address;
                    const tokenAddressTo =
                        TokenConfig[tokenName][
                            layer === Layer.layer1 ? Layer.layer2 : Layer.layer1
                        ][env].address;

                    if (!tokenAddressFrom || !tokenAddressTo) {
                        console.error(
                            `Token ${tokenName}, layer1:${tokenAddressFrom}, layer2: ${tokenAddressTo} address error`,
                        );
                    }
                    const estimateRes = await getGasEstimateFromServer();
                    const l2gasRes = await getL2GasFromMetisOracle();

                    console.log(
                        'estimateResFromServerL2',
                        estimateRes ? estimateRes.l2.toFixed() : 'null',
                    );
                    console.log(
                        'l2gasRes',
                        l2gasRes ? l2gasRes.toFixed() : 'null',
                    );
                    if (estimateRes && l2gasRes) {
                        const gasParams = l2gasRes.isGreaterThan(estimateRes.l2)
                            ? l2gasRes
                            : estimateRes.l2;
                        const discount = await getOracleDiscount();
                        console.log(
                            'discount',
                            discount ? discount.toFixed() : 'null',
                        );
                        if (discount) {
                            return {
                                method: 'depositERC20To',
                                params: [
                                    // L1 metis token address
                                    tokenAddressFrom,
                                    // L2 metis token address, enter any address is fine
                                    tokenAddressTo,
                                    receivingAddress,
                                    amount
                                        .shiftedBy(
                                            tokens[tokenName][layer].decimals,
                                        )
                                        .toFixed(),
                                    gasParams.toFixed(),
                                    '0x',
                                ],
                                tooltips: true,
                                extends: {
                                    // value: gasParams
                                    //     .multipliedBy(discount)
                                    //     .integerValue()
                                    //     .toFixed(),
                                    value: '0',
                                },
                            };
                        }
                    }
                } else {
                    const minGas = await getLayer2TransferAtLeaseTokenAmount();
                    if (minGas) {
                        const l2TokenAddress =
                            TokenConfig[tokenName][layer][env].address;

                        if (l2TokenAddress) {
                            return {
                                method: 'withdrawTo',
                                params: [
                                    l2TokenAddress,
                                    receivingAddress,
                                    amount
                                        .shiftedBy(
                                            tokens[tokenName][layer].decimals,
                                        )
                                        .toFixed(),
                                    '0',
                                    '0x',
                                ],
                                tooltips: true,
                                extends: {
                                    value: minGas.toFixed(),
                                },
                            };
                        } else {
                            console.error(
                                `Token ${tokenName}, lido layer2 address return empty address`,
                            );
                        }
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('getBridgeTokenParams error');
                handleError(e);
            }
        }

        return undefined;
    }

    async function estimateLidoBridgeTokenGas(
        layer: Layer,
        amount: BigNumber,
        tokenName: Token,
        params: LidoBridgeParams,
    ) {
        if (user.address && params) {
            try {
                // make sure layer2 metis have enouge metis token to transfer
                let transFee = 0;

                if (isPolis && client) {
                    console.error('not support polis');
                } else if (bridgeContract) {
                    const res = await liBridgeContract.methods[params.method](
                        ...params.params,
                    ).estimateGas({
                        from: user.address,
                        value: params.extends.value,
                    });
                    //here
                    if (res) {
                        const gasPrice = await user.web3.eth.getGasPrice();
                        transFee = new BigNumber(res)
                            .multipliedBy(gasPrice)
                            .shiftedBy(-18)
                            .toNumber();
                    }
                }
                let estimateRemainingMainCoin = new BigNumber(
                    params.extends.value,
                )
                    .shiftedBy(-18)
                    .plus(transFee);
                if (layer === Layer.layer1 && tokenName == Token.eth) {
                    estimateRemainingMainCoin =
                        estimateRemainingMainCoin.minus(amount);
                }
                return {
                    fee_num: transFee,
                    // layer1 transfer use eth value to transfer, services fee = 0
                    servicesFee:
                        layer === Layer.layer1 && tokenName === Token.eth
                            ? 0
                            : params.extends.value
                            ? new BigNumber(params.extends.value)
                                  .shiftedBy(-18)
                                  .toNumber()
                            : 0,
                    estimateRemainingMainCoin,
                };
            } catch (e) {
                console.error('estimateLidoBridgeTokenGas error');
                console.error(e);
            }
        }

        return undefined;
    }

    async function lidoBridgeToken(
        layer: Layer,
        receivingAddress: string,
        amount: BigNumber,
        tokenName: Token,
    ) {
        if (user.address) {
            const params = await getLidoBridgeParams(
                layer,
                receivingAddress,
                amount,
                tokenName,
            );
            console.log('lidoBridgeToken');
            console.log(params);
            // gas logic

            if (params) {
                try {
                    const estimateGas = await estimateLidoBridgeTokenGas(
                        layer,
                        amount,
                        tokenName,
                        params,
                    );

                    console.log('estimateGas', estimateGas);

                    if (layer === Layer.layer1) {
                        if (
                            (estimateGas &&
                                estimateGas.estimateRemainingMainCoin.toNumber() &&
                                estimateGas.estimateRemainingMainCoin
                                    .plus(tokenName === Token.eth ? amount : 0)
                                    .isGreaterThan(layer1EthBalance)) ||
                            layer1EthBalance
                                .shiftedBy(18)
                                .isLessThan(params.extends.value)
                        ) {
                            ModalFunc({
                                type: 'sorry',
                                title: 'Sorry',
                                text: `The current ETH Token balance ${layer1EthBalance.toFixed(
                                    3,
                                )} on your account is not sufficient to support this execution. Please leave at least ${(estimateGas
                                    ? estimateGas.estimateRemainingMainCoin.plus(
                                          estimateGas.fee_num,
                                      )
                                    : new BigNumber(
                                          params.extends.value,
                                      ).shiftedBy(-18)
                                ).toFixed(6)} ETH to support this execution`,
                                buttonText: 'OK',
                            });
                            return false;
                        }
                    } else {
                        if (
                            (estimateGas &&
                                estimateGas.estimateRemainingMainCoin.toNumber() &&
                                estimateGas.estimateRemainingMainCoin
                                    .plus(
                                        tokenName === Token.metis ? amount : 0,
                                    )
                                    .isGreaterThan(layer2MetisBalance)) ||
                            layer2MetisBalance
                                .shiftedBy(18)
                                .isLessThan(params.extends.value)
                        ) {
                            ModalFunc({
                                type: 'sorry',
                                title: 'Sorry',
                                text: `The current Metis Token balance ${layer2MetisBalance.toFixed(
                                    3,
                                )} on your account is not sufficient to support this execution. Please leave at least ${(estimateGas
                                    ? estimateGas.estimateRemainingMainCoin.plus(
                                          estimateGas.fee_num,
                                      )
                                    : new BigNumber(
                                          params.extends.value,
                                      ).shiftedBy(-18)
                                ).toFixed(6)} METIS to support this execution`,
                                buttonText: 'OK',
                            });
                            return false;
                        }
                    }

                    if (isPolis && client) {
                        console.error('not support polis');
                        return false;
                    } else if (liBridgeContract) {
                        console.log(
                            'liBridgeContract',
                            liBridgeContract._address,
                        );
                        console.log('params', params.params);
                        const res = await new Promise((resolve, reject) => {
                            liBridgeContract.methods[params.method](
                                ...params.params,
                            )
                                .send({
                                    from: user.address,
                                    value: params.extends.value,
                                    // gasLimit: Math.floor(block.gasUsed * 1.2),
                                    gasLimit:
                                        layer === Layer.layer1
                                            ? 400000
                                            : 2000000,
                                })
                                .on('receipt', function (receipt: any) {
                                    resolve(receipt.transactionHash);
                                })
                                .on('error', function (e: any) {
                                    reject(e);
                                });
                        });

                        return res;
                    }
                } catch (e) {
                    console.error(e);
                    console.error('bridgeToken error');
                    handleError(e);
                }
            } else {
                console.log('getBridgeTokenParams empty error');
            }
        }

        return false;
    }

    const clientReady = useMemo(() => {
        return !!client;
    }, [client]);

    const clientWithUserReady = useMemo(() => {
        return !!client && !!user && !!user.connected;
    }, [client, user]);

    return {
        client,
        clientReady,
        clientWithUserReady,
        bridgeToken,
        getBlockNumberFromHash,
        getCurrnetBlockNumber,
        getLayer2GasOracleminL1GasLimit,
        getLayer2TransferAtLeaseTokenAmount,
        getBridgeTokenParams,
        estimateBridgeTokenGas,
        lidoBridgeToken,
        estimateLidoBridgeTokenGas,
        getLidoBridgeParams,
    };
}
