import React, { lazy, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Row, { RowBetween } from 'components/Row';
import { Error } from 'components/Text';
import Button from 'components/Button';
import NumberInput from './NumberInput';
import {
    Env,
    Layer,
    LidoTokenList,
    NETWORK_CONFIG,
    Token,
    TokenConfig,
} from '../../constants';
import BigNumber from 'bignumber.js';
import AlertFunc from 'utils/components/alert';
import useMiddleareClient from 'hooks/use-middleware-client';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// import Modal from 'utils/components/modal';
import { setIsOpenConnectModalHooks } from 'state/user/hooks';
import Alert from 'components/Alert';
import { setLayer } from 'state/metisMiddlewareClient';
import useTokenApprove from 'hooks/use-token-approve';
import { updateTokenBalanceHooks } from 'state/tokens/hooks';
import ModalFunc from 'utils/components/modal';
import useWeb3SwitchNetwork from 'hooks/use-web3-switch-network';
import ThirdPartyList from './thirdPartyList';
import styled from 'styled-components';
import { AutoColumn } from 'components/Column';
import { TYPE } from 'theme';
import TooltipInfo from 'components/TooltipInfo';
import moment from 'moment';
import Modal from 'components/Modal';
import TipSVG from 'assets/images/tips.svg';
import ICPlusSVG from 'assets/images/icon_+.svg';
import ICMinsSVG from 'assets/images/icon_-.svg';
import LogoSVG from 'assets/images/logo.svg';
import DateSVG from 'assets/images/date.svg';
import SwitchSVG from 'assets/images/ic_switch.svg';
import Noti from './Noti';
import useLidoTokenApprove from 'hooks/use-lido-token-approve';

const Wrapper = styled.div`
    width: 1000px;

    box-sizing: border-box;
    margin: 0 auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
    `}
    .infoIcon {
        // margin-right: 10px;
        cursor: pointer;
        width: 24px;
        height: 24px;
        background-image: url(${TipSVG});
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-top: 0;
        `}
    }
    .historylink {
        font-size: 14px;
        color: ${({ theme }) => theme.c.t04};
        line-height: 19px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const PageWrapper = styled.div`
    position: relative;
    display: block;

    width: fit-content;
    margin: 0 auto;
    // margin-top: 30px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `}

    .innerContainer {
        position: relative;
        width: 540px;
        background: ${({ theme }) => theme.c.bg05};
        border-radius: 48px;
        box-sizing: border-box;
        padding: 30px;
        overflow: hidden;
        box-shadow: 1px 0px 24px 4px rgba(7, 221, 208, 0.2);
        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        border-radius: 24px;
        padding: 1rem;
        `};
    }

    .shadow-top {
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        box-shadow: 1px 0px 96px 1px rgba(7, 221, 208, 0.24);
        border-radius: 170px 170px 0 0;
        z-index: -1;
    }

    .shadow-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 50%;
        right: 0;
        box-shadow: 1px 0px 96px 1px rgba(255, 0, 199, 0.32);
        border-radius: 0 0 170px 170px;
        z-index: -1;
    }

    /* box-shadow: 2px 0px 41px 1px rgba(0, 170, 150, 0.3); */
    .icon {
        color: $lightGreen;
    }

    .disclaimer {
        position: absolute;
        right: 30px;
        top: 30px;
        width: fit-content;
        transform: translateY(25%);
        ${({ theme }) => theme.mediaWidth.upToSmall`
            height: 20px;
            right:1rem;
            top: 1rem;
            img {
                width: 20px;
                height: 20px;
            }
        `};
    }

    .receivingAddress {
        width: 100%;
        background: ${({ theme }) => theme.c.bg04};
        border-radius: 15px;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 16px;
        .receivingAddressInput {
            background: transparent;
            height: 30px;
            outline: none;
            border: none;
            color: ${({ theme }) => theme.c.t01};
            font-size: 14px;
            font-weight: bold;
            width: 100%;

            &::placeholder {
                color: ${({ theme }) => theme.c.t02};
            }
        }
    }

    .deposit {
        width: 280px;
        margin-top: 24px;
        font-size: 18px !important;

        box-shadow: none !important;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        margin-top: 12px;
        `}// &:hover {
        //     background-color: rgba(101, 218, 207, 0.3);
        // }
    }

    &.bridgeDetail {
        padding-top: 20px;
        .bridge {
            background: #122537;
            border-radius: 4px;
            margin-top: 20px;
            box-sizing: border-box;
            width: 100%;
            padding: 20px;
        }

        .transDetail {
            margin-top: 20px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-sizing: border-box;
            padding: 10px;

            .label {
                padding-right: 10px;
            }

            .logo {
                width: 32px;
                height: 32px;
                margin-right: 10px;
                border-radius: 50%;
            }
        }
    }

    &.bridgeHistory {
        padding: 24px;
        width: 100%;
        max-width: 1088px;

        .historyPaper {
            margin-top: 20px;
            background: #060816;

            :global {
                .MuiTableCell-head {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.5);
                    line-height: 16px;
                    border-bottom: none;
                    padding: 16px 0;
                    letter-spacing: -1px;
                }
                .MuiTableCell-body {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 16px;
                    border: none;
                }
                .MuiTablePagination-root {
                    color: #ffffff;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;

                    .MuiTablePagination-actions {
                        margin-left: 0px;
                    }

                    .MuiIconButton-root.Mui-disabled {
                        display: none;
                    }
                }
                .MuiTablePagination-selectRoot {
                    display: none;
                }
            }
        }
        .historyContainer {
            width: 100%;
            overflow-x: unset;
            .addressLink {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 16px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
            .tokenInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    max-width: 24px;
                    margin-right: 8px;
                }
            }
        }
    }
`;

const SwtichNetworkWrapper = styled(RowBetween)`
    background: ${({ theme }) => theme.c.bg04};
    margin-top: 16px;

    border-radius: 40px;
    box-sizing: border-box;
    padding: 8px;
    height: 80px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        height: fit-content;
        padding: 0;
        margin-top: 8px;
    `};
`;

const NetworkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border-radius: 40px;
    flex-grow: 0;
    box-sizing: border-box;
    width: 40%;
    height: 100%;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 40px;
    height: 60px;
    padding: 10px;
    margin-top: 8px;
    width: fit-content;

    > div {
        font-size: 14px;
    }
    `}

    &.second {
        flex-direction: row-reverse;

        .netIcon {
            margin-right: 0;
            margin-left: 16px;
            ${({ theme }) => theme.mediaWidth.upToSmall`
             margin-right: 0;
            margin-left: 8px;
            `}
        }
    }

    .netIcon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 32px;
        height: 32px;
       margin-right: 8px;
       flex-shrink: 0;
        `}

        &.etherum {
            background-image: url(${require('assets/images/eth_logo.png')});
        }
        &.metis {
            background-image: url(${LogoSVG});
        }
    }
`;

const SwtichIcon = styled.div`
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    background-image: url(${SwitchSVG});
    width: 44px;
    height: 36px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8px;
    width: 36px;
    height: 27px;
    `}/* margin-top: 20px; */
`;

const InputPanel = styled.div`
    width: 100%;
    margin-top: 24px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 12px;
    `}
`;

const SendIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;

    &.plus {
        background-image: url(${ICPlusSVG});
    }
    &.delete {
        background-image: url(${ICMinsSVG});
    }
`;

const DisclaimerWrapper = styled.div`
    width: 464px;
    /* height: 206px; */
    box-sizing: border-box;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: fit-content;
`}
`;

const DiscalaimerButton = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    /* width: 80px; */
    box-sizing: border-box;
    padding: 0 6px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 170, 150, 0.1);
    text-align: center;
    border-radius: 8px;
    font-size: 10px;
    color: #00cfff;
    cursor: pointer;
`;

const DateIcon = styled.div`
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background: url(${DateSVG}) 100% center no-repeat;
`;

const SwitchNetworkHeader = styled(RowBetween)`
    margin-top: 24px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 12px;
    `}
`;

const AirdropImg = styled.img`
    width: 540px;
    height: auto;
    margin-top: 12px;
    margin-bottom: 32px;
    cursor: pointer;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
`;

const AirdropImgMobile = styled.img`
    display: none;
    width: calc(100vw - 2rem);
    height: auto;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    `}
`;

const GasFeeWrapper = styled(RowBetween)`
    width: 100%;
    border: 2px solid #00cfff;
    padding: 10px 14px;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: rgba(0, 207, 255, 0.1);
`;

const CONFIRM_BLOCKNUMBERS = 12;

export default function Homepage() {
    const dispatch = useAppDispatch();
    const { handleChangeNetworkByEnvAndLayer } = useWeb3SwitchNetwork();
    const { tokens } = useAppSelector((state) => state.tokens);
    const { layer } = useAppSelector((state) => state.metisMiddlewareClient);
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();
    const [currentToken, setCurrentToken] = useState(Token.metis);
    const [inputAmount, setInputAmount] = useState(new BigNumber(0));
    const updateTokenBalance = updateTokenBalanceHooks();
    const [showSend, setShowSend] = useState(false);
    const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);

    const { approved, approveUserTokenForSpender } = useTokenApprove(
        currentToken,
        layer,
        inputAmount,
    );

    const {
        approved: approvedLido,
        approveUserTokenForSpender: approveLidoSpender,
    } = useLidoTokenApprove(currentToken, layer, inputAmount);

    const [transferring, setTransferring] = useState(false);

    const [where, setWhere] = useState('init');
    const {
        bridgeToken,
        lidoBridgeToken,
        getBlockNumberFromHash,
        getCurrnetBlockNumber,
        getBridgeTokenParams,
        estimateBridgeTokenGas,
        getLidoBridgeParams,
        estimateLidoBridgeTokenGas,
    } = useMiddleareClient();
    const {
        connected,
        address: walletAddress,
        isPolis,
    } = useAppSelector((state) => state.user);
    const isDeposit = useMemo(() => {
        return layer === Layer.layer1;
    }, [layer]);
    const { env } = useAppSelector((state) => state.metisMiddlewareClient);
    const [receivingAddress, setReceivingAddress] = useState('');
    const [inputErrorMessage, setInputErrorMessage] = useState<
        string | undefined
    >(undefined);

    const [confirmBlockNumber, setConfirmBlockNumber] = useState<number>(0);
    const [transAlertShow, setTransAlertShow] = useState<boolean>(false);
    const [transFeeInfo, setTransFeeInfo] = useState<{
        fee_num: number;
        servicesFee: number;
    }>({
        fee_num: 0,
        servicesFee: 0,
    });
    const [loadingTransFee, setLoadingTransFee] = useState(false);
    const mainnetToken = layer === Layer.layer1 ? Token.eth : Token.metis;
    useEffect(() => {
        if (walletAddress) {
            setReceivingAddress(walletAddress);
        }
    }, [walletAddress]);

    useEffect(() => {
        setInputAmount(new BigNumber(0));
        setCurrentToken(Token.metis);
    }, [env]);

    const currentTokenDetail = useMemo(() => {
        return tokens[currentToken];
    }, [tokens, currentToken]);

    // const layer2LimitError = useMemo(() => {
    //     if (isDeposit) return '';
    //     return inputAmount.toNumber() &&
    //         inputAmount
    //             .plus(layer2TransferMin)
    //             .isGreaterThan(metisLayer2Balance)
    //         ? `Please leave at least ${layer2TransferMin.toFixed()} Metis token to support this execution.`
    //         : '';
    // }, [inputAmount, layer2TransferMin, isDeposit, metisLayer2Balance]);

    const layer2LimitError = '';

    const nextDisabled = useMemo(() => {
        return (
            !receivingAddress ||
            !inputAmount.toNumber() ||
            !!inputErrorMessage ||
            !!layer2LimitError
        );
    }, [
        receivingAddress,
        inputAmount,
        inputErrorMessage,
        isDeposit,
        layer2LimitError,
    ]);

    const isLidoToken = useMemo(() => {
        return LidoTokenList.filter((item) => item === currentToken).length > 0;
    }, [currentToken]);

    async function handleNext() {
        if (!connected) {
            setIsOpenConnectModal(true);
        } else {
            if (isPolis) {
                ModalFunc({
                    type: 'info',
                    title: 'Notice',
                    text: `NUVO wallet doesn't support Sepolia network yet,which is joining the Sepolia network right now.If you want to still use the Sepolia network, please log in with other wallets.`,
                    buttonText: 'Got it',
                });
                return;
            }
            if (approved) {
                await transfer();
            } else {
                await approveUserTokenForSpender();
            }
        }
    }

    async function handleLidoNext() {
        if (!connected) {
            setIsOpenConnectModal(true);
        } else {
            if (isPolis) {
                ModalFunc({
                    type: 'info',
                    title: 'Notice',
                    text: `NUVO wallet doesn't support Sepolia network yet,which is joining the Sepolia network right now.If you want to still use the Sepolia network, please log in with other wallets.`,
                    buttonText: 'Got it',
                });
                return;
            }
            if (approvedLido) {
                await transfer();
            } else {
                await approveLidoSpender();
            }
        }
    }

    function handleDestinationAddressOnChange(
        e: React.ChangeEvent<HTMLInputElement>,
    ) {
        setReceivingAddress(e.currentTarget.value);
    }

    function transSuccess(
        tx: string,
        amount: BigNumber,
        layer: Layer,
        tokenName: string,
    ) {
        AlertFunc({
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            },
            key: `${Math.random()}`,
            text: (
                <>
                    <Row lineHeight="none">
                        Transfer {amount.toNumber()} {tokenName} to{' '}
                        {
                            NETWORK_CONFIG[env][
                                layer === Layer.layer1
                                    ? Layer.layer2
                                    : Layer.layer1
                            ].name
                        }
                    </Row>
                    <Row lineHeight="none">
                        <a
                            href={`${NETWORK_CONFIG[env][layer].explore}tx/${tx}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View on {NETWORK_CONFIG[env][layer].scanLable}
                        </a>
                    </Row>
                </>
            ),
            type: 'success',
            duration: 8000,
        });
    }

    async function transfer() {
        setTransferring(true);
        const tx = isLidoToken
            ? await lidoBridgeToken(
                  layer,
                  receivingAddress,
                  inputAmount,
                  currentToken,
              )
            : await bridgeToken(
                  layer,
                  receivingAddress,
                  inputAmount,
                  currentToken,
              );
        if (tx) {
            if (isDeposit) {
                setTransAlertShow(true);

                const transferAmount = new BigNumber(inputAmount);

                const interval = setInterval(async () => {
                    const txNumber = await getBlockNumberFromHash(
                        Layer.layer1,
                        tx,
                    );

                    const currentBlockNumber = await getCurrnetBlockNumber(
                        Layer.layer1,
                    );
                    if (
                        currentBlockNumber &&
                        txNumber &&
                        currentBlockNumber > txNumber
                    ) {
                        const confirm = currentBlockNumber - txNumber;
                        if (confirm < CONFIRM_BLOCKNUMBERS) {
                            setConfirmBlockNumber(confirm);
                        } else {
                            setTransAlertShow(false);
                            setConfirmBlockNumber(0);
                            // setTransferAmount(new BigNumber(0));
                            clearInterval(interval);
                            transSuccess(
                                tx,
                                transferAmount,
                                Layer.layer1,
                                TokenConfig[currentToken][Layer.layer1].symbol,
                            );
                        }
                    }
                }, 3000);
            } else {
                transSuccess(
                    tx,
                    inputAmount,
                    Layer.layer2,
                    TokenConfig[currentToken][Layer.layer2].symbol,
                );
            }

            updateTokenBalance(currentToken, Layer.layer1);
            updateTokenBalance(currentToken, Layer.layer2);

            setWhere('init');
            setInputAmount(new BigNumber(0));
        }
        setTransferring(false);
    }

    async function handleTabChange(layerParams: Layer) {
        if (layer === layerParams) return;
        const success = await handleChangeNetworkByEnvAndLayer(
            env,
            layerParams,
        );
        if (success) {
            dispatch(setLayer(layerParams));
        }
    }

    async function getTransFeeDetail() {
        if (!currentTokenDetail[layer] || !approved || transferring) return;
        const balance = currentTokenDetail[layer].balance;
        if (
            inputAmount.isGreaterThan(0) &&
            balance.isGreaterThanOrEqualTo(inputAmount)
        ) {
            try {
                setLoadingTransFee(true);
                const params: any = isLidoToken
                    ? await getLidoBridgeParams(
                          layer,
                          receivingAddress,
                          balance,
                          currentToken,
                      )
                    : await getBridgeTokenParams(
                          layer,
                          receivingAddress,
                          balance,
                          currentToken,
                      );
                if (params) {
                    const estimateGas = isLidoToken
                        ? await estimateLidoBridgeTokenGas(
                              layer,
                              balance,
                              currentToken,
                              params,
                          )
                        : await estimateBridgeTokenGas(
                              layer,
                              balance,
                              currentToken,
                              params,
                          );
                    setTransFeeInfo({
                        fee_num:
                            estimateGas && estimateGas.fee_num
                                ? estimateGas.fee_num
                                : 0,
                        servicesFee:
                            estimateGas && estimateGas.servicesFee
                                ? estimateGas.servicesFee
                                : 0,
                    });
                }
            } catch (e) {
                console.error('getTransFeeDetail error');
                console.error(e);
            }
            setLoadingTransFee(false);
        }
    }

    // get transfee detail
    useEffect(() => {
        if (inputErrorMessage || !approved || !receivingAddress || transferring)
            return;
        getTransFeeDetail();
    }, [
        currentTokenDetail,
        inputAmount,
        inputErrorMessage,
        layer,
        receivingAddress,
        currentToken,
        approved,
        transferring,
        isLidoToken,
    ]);

    async function getLeftAmount(): Promise<BigNumber | undefined> {
        if (!currentTokenDetail[layer]) return undefined;
        const balance = currentTokenDetail[layer].balance;
        const params = await getBridgeTokenParams(
            layer,
            receivingAddress,
            balance,
            currentToken,
        );
        if (params) {
            const estimateGas = await estimateBridgeTokenGas(
                layer,
                balance,
                currentToken,
                params,
            );

            return estimateGas?.estimateRemainingMainCoin;
        }

        return undefined;
    }

    async function handleMax() {
        if (!currentTokenDetail[layer]) return;
        const balance = currentTokenDetail[layer].balance;

        if (
            balance.isGreaterThan(0) &&
            ((layer === Layer.layer1 && currentToken === Token.eth) ||
                (layer === Layer.layer2 && currentToken === Token.metis))
        ) {
            try {
                const params = await getBridgeTokenParams(
                    layer,
                    receivingAddress,
                    balance,
                    currentToken,
                );
                // gas logic
                if (params) {
                    const estimateGas = await estimateBridgeTokenGas(
                        layer,
                        balance,
                        currentToken,
                        params,
                    );

                    console.log('params', params);
                    console.log(
                        'estimateRemainingMainCoin',
                        estimateGas
                            ? estimateGas.estimateRemainingMainCoin.toFixed()
                            : undefined,
                    );

                    if (estimateGas && estimateGas.estimateRemainingMainCoin) {
                        const leftAmount = balance
                            .minus(estimateGas.estimateRemainingMainCoin)
                            .minus(layer === Layer.layer2 ? 0.1 : 0.005)
                            .minus(estimateGas.fee_num || 0);

                        if (
                            estimateGas.estimateRemainingMainCoin.isGreaterThan(
                                balance,
                            ) ||
                            leftAmount.isLessThanOrEqualTo(0)
                        ) {
                            ModalFunc({
                                type: 'sorry',
                                title: 'Sorry',
                                text: `The current ${
                                    TokenConfig[currentToken][layer].symbol
                                } Token balance ${balance.toFixed(
                                    3,
                                )} on your account is not sufficient to support this execution. Please leave at least ${estimateGas.estimateRemainingMainCoin
                                    .plus(estimateGas.fee_num)
                                    .toFixed(6)} ${
                                    TokenConfig[currentToken][layer].symbol
                                } to support this execution`,
                                buttonText: 'OK',
                            });
                        } else {
                            setInputAmount(leftAmount);

                            AlertFunc({
                                type: 'info',
                                text: `Your estimated gas fee has been subtracted from your balance.`,
                                duration: 10000,
                            });
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            setInputAmount(balance);
        }
    }

    function formatGasNum(fee: number) {
        if (!approved || fee === -1 || fee === 0) {
            return '-';
        }
        if (fee < 1e-6) {
            return '< 0.000001';
        } else {
            const feeString = `${fee}`;
            if (feeString.length > 6) {
                return new BigNumber(fee).toFixed(6);
            }
            return new BigNumber(fee).toFixed();
        }
    }

    return (
        <Wrapper>
            <Noti />
            <PageWrapper>
                <div className="shadow-top" />
                <div className="shadow-bottom" />
                <div className="innerContainer">
                    {/* <Row className="disclaimer" justify="center">
                        <div
                            color="#00cfff"
                            className={'infoIcon'}
                            onClick={() => {
                                setDisclaimerModalOpen(true);
                            }}
                        />
                    </Row> */}
                    <Row
                        justify="center"
                        style={{
                            flexDirection: 'column',
                            position: 'relative',
                        }}
                    >
                        <TYPE.main
                            fontSize={18}
                            fontWeight={600}
                            letterSpacing={2}
                            color="t03"
                            lineHeight={1}
                        >
                            BRIDGE
                        </TYPE.main>
                        <TYPE.main
                            fontSize={22}
                            fontWeight={600}
                            letterSpacing={2}
                            lineHeight={1.2}
                        >
                            TOKENS
                        </TYPE.main>
                        {/* <DiscalaimerButton
                            onClick={() => {
                                setDisclaimerModalOpen(true);
                            }}
                        >
                            Bridge
                            <br />
                            Rebate Rules
                        </DiscalaimerButton> */}
                    </Row>
                    <SwitchNetworkHeader>
                        <TYPE.main fontSize={14} fontWeight={500}>
                            FROM NETWORK
                        </TYPE.main>
                        <TYPE.main fontSize={14} fontWeight={500}>
                            TO NETWORK
                        </TYPE.main>
                    </SwitchNetworkHeader>
                    <SwtichNetworkWrapper>
                        <NetworkWrapper>
                            <div
                                className={cx(
                                    'netIcon',
                                    isDeposit ? 'etherum' : 'metis',
                                )}
                            />
                            <TYPE.main className="name">
                                {
                                    NETWORK_CONFIG[env][
                                        isDeposit ? Layer.layer1 : Layer.layer2
                                    ].name
                                }
                            </TYPE.main>
                        </NetworkWrapper>
                        <AutoColumn
                            justify="center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (isDeposit) {
                                    handleTabChange(Layer.layer2);
                                } else {
                                    handleTabChange(Layer.layer1);
                                }
                            }}
                        >
                            <SwtichIcon />
                            <TYPE.main fontSize={12}>Switch</TYPE.main>
                        </AutoColumn>
                        <NetworkWrapper className="second">
                            <div
                                className={cx(
                                    'netIcon',
                                    !isDeposit ? 'etherum' : 'metis',
                                )}
                            />
                            <TYPE.main className="name">
                                {
                                    NETWORK_CONFIG[env][
                                        !isDeposit ? Layer.layer1 : Layer.layer2
                                    ].name
                                }
                            </TYPE.main>
                        </NetworkWrapper>
                    </SwtichNetworkWrapper>
                    <InputPanel>
                        <Row lineHeight="none">
                            <NumberInput
                                network={
                                    isDeposit
                                        ? NETWORK_CONFIG[env][Layer.layer1].name
                                        : NETWORK_CONFIG[env][Layer.layer2].name
                                }
                                balance={
                                    currentTokenDetail
                                        ? currentTokenDetail[layer].balance
                                        : new BigNumber(0)
                                }
                                onChange={setInputAmount}
                                value={inputAmount}
                                onError={setInputErrorMessage}
                                isSearch={true}
                                layer={isDeposit ? Layer.layer1 : Layer.layer2}
                                token={currentToken}
                                onSelectToken={setCurrentToken}
                                handleMax={handleMax}
                                hideAddToken={!connected}
                                getLeftAmount={getLeftAmount}
                            />
                        </Row>
                        {(inputErrorMessage || layer2LimitError) && (
                            <Row>
                                <Error>
                                    {inputErrorMessage || layer2LimitError}
                                </Error>
                            </Row>
                        )}

                        {connected && (
                            <>
                                <AutoColumn
                                    justify="center"
                                    gap="md"
                                    style={{ marginTop: '12px' }}
                                >
                                    <TYPE.main
                                        color="t03"
                                        fontSize={16}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: ' pointer',
                                        }}
                                        onClick={() => {
                                            if (
                                                showSend &&
                                                connected &&
                                                walletAddress
                                            ) {
                                                setReceivingAddress(
                                                    walletAddress,
                                                );
                                            }
                                            setShowSend(!showSend);
                                        }}
                                    >
                                        <SendIcon
                                            className={
                                                showSend ? 'delete' : 'plus'
                                            }
                                        />
                                        Send to
                                    </TYPE.main>
                                    {showSend && (
                                        <div className={'receivingAddress'}>
                                            <Row lineHeight="none">
                                                <TYPE.desc
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Receiving address
                                                    <TooltipInfo
                                                        text="Please watch out that
                                                        wrong ERC20 address will
                                                        not be accepted."
                                                    />
                                                </TYPE.desc>
                                            </Row>
                                            <Row>
                                                <input
                                                    type="text"
                                                    autoFocus={showSend}
                                                    value={receivingAddress}
                                                    className={
                                                        'receivingAddressInput'
                                                    }
                                                    onChange={
                                                        handleDestinationAddressOnChange
                                                    }
                                                />
                                            </Row>
                                        </div>
                                    )}
                                    <RowBetween>
                                        <TYPE.main
                                            fontSize={12}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Estimated time of Arrival
                                            <TooltipInfo
                                                text={
                                                    <TYPE.main
                                                        fontSize={12}
                                                        color="#fff"
                                                    >
                                                        {isDeposit
                                                            ? 'From Etherum to Andromeda: less than 10 minuntes'
                                                            : 'Withdrawals from Metis Andromeda to Etherum Mainnet require a wait time of one week.'}
                                                    </TYPE.main>
                                                }
                                            />
                                        </TYPE.main>
                                        <TYPE.main
                                            fontSize={12}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Estimated Fee
                                            <TooltipInfo
                                                text={
                                                    isDeposit ? (
                                                        <AutoColumn>
                                                            <TYPE.main
                                                                fontSize={12}
                                                                color="#fff"
                                                            >
                                                                Estimated Gas
                                                                Fee:{' '}
                                                                {loadingTransFee
                                                                    ? 'Calculating'
                                                                    : `${formatGasNum(
                                                                          transFeeInfo
                                                                              ? transFeeInfo.fee_num
                                                                              : -1,
                                                                      )} ETH`}
                                                            </TYPE.main>
                                                            <TYPE.main
                                                                fontSize={12}
                                                                color="#fff"
                                                            >
                                                                {
                                                                    'The gas cost could change from time to time. Please review before you confirm the transaction.'
                                                                }
                                                            </TYPE.main>
                                                        </AutoColumn>
                                                    ) : (
                                                        <AutoColumn>
                                                            <TYPE.main
                                                                fontSize={12}
                                                                color="#fff"
                                                            >
                                                                Estimated Gas
                                                                Fee:{' '}
                                                                {loadingTransFee
                                                                    ? 'Calculating'
                                                                    : `${formatGasNum(
                                                                          transFeeInfo
                                                                              ? transFeeInfo.fee_num
                                                                              : -1,
                                                                      )} Metis`}
                                                            </TYPE.main>
                                                            <AutoColumn>
                                                                <TYPE.main
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    color="#fff"
                                                                >
                                                                    The Protocol
                                                                    Fee:{' '}
                                                                    {loadingTransFee
                                                                        ? 'Calculating'
                                                                        : `${formatGasNum(
                                                                              transFeeInfo
                                                                                  ? transFeeInfo.servicesFee
                                                                                  : -1,
                                                                          )} Metis`}
                                                                </TYPE.main>
                                                                <TYPE.main
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    color="#fff"
                                                                >
                                                                    {
                                                                        'The gas cost could changes from time to time. Please review before you confirm the transaction. Protocol Fee is paid to withdraw tokens to Etherum Mainnet.'
                                                                    }
                                                                </TYPE.main>
                                                            </AutoColumn>
                                                        </AutoColumn>
                                                    )
                                                }
                                            />
                                        </TYPE.main>
                                    </RowBetween>
                                    <GasFeeWrapper>
                                        <AutoColumn
                                            gap="4px"
                                            style={{ width: '100%' }}
                                        >
                                            <RowBetween>
                                                <TYPE.main fontSize={12}>
                                                    {`${
                                                        NETWORK_CONFIG[env][
                                                            isDeposit
                                                                ? Layer.layer1
                                                                : Layer.layer2
                                                        ].name
                                                    } - ${
                                                        NETWORK_CONFIG[env][
                                                            !isDeposit
                                                                ? Layer.layer1
                                                                : Layer.layer2
                                                        ].name
                                                    }`}
                                                </TYPE.main>
                                                {false && (
                                                    <TYPE.main
                                                        fontSize={12}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        Estimated Fee
                                                        <TooltipInfo
                                                            text={
                                                                isDeposit ? (
                                                                    <AutoColumn>
                                                                        <TYPE.main
                                                                            fontSize={
                                                                                12
                                                                            }
                                                                            color="#fff"
                                                                        >
                                                                            Estimated
                                                                            Gas
                                                                            Fee:{' '}
                                                                            {loadingTransFee
                                                                                ? 'Calculating'
                                                                                : `${formatGasNum(
                                                                                      transFeeInfo
                                                                                          ? transFeeInfo.fee_num
                                                                                          : -1,
                                                                                  )} ETH`}
                                                                        </TYPE.main>
                                                                        <TYPE.main
                                                                            fontSize={
                                                                                12
                                                                            }
                                                                            color="#fff"
                                                                        >
                                                                            {
                                                                                'The gas cost could change from time to time. Please review before you confirm the transaction.'
                                                                            }
                                                                        </TYPE.main>
                                                                    </AutoColumn>
                                                                ) : (
                                                                    <AutoColumn>
                                                                        <TYPE.main
                                                                            fontSize={
                                                                                12
                                                                            }
                                                                            color="#fff"
                                                                        >
                                                                            Estimated
                                                                            Gas
                                                                            Fee:{' '}
                                                                            {loadingTransFee
                                                                                ? 'Calculating'
                                                                                : `${formatGasNum(
                                                                                      transFeeInfo
                                                                                          ? transFeeInfo.fee_num
                                                                                          : -1,
                                                                                  )} Metis`}
                                                                        </TYPE.main>
                                                                        <AutoColumn>
                                                                            <TYPE.main
                                                                                fontSize={
                                                                                    12
                                                                                }
                                                                                color="#fff"
                                                                            >
                                                                                The
                                                                                Protocol
                                                                                Fee:{' '}
                                                                                {loadingTransFee
                                                                                    ? 'Calculating'
                                                                                    : `${formatGasNum(
                                                                                          transFeeInfo
                                                                                              ? transFeeInfo.servicesFee
                                                                                              : -1,
                                                                                      )} Metis`}
                                                                            </TYPE.main>
                                                                            <TYPE.main
                                                                                fontSize={
                                                                                    12
                                                                                }
                                                                                color="#fff"
                                                                            >
                                                                                {
                                                                                    'The gas cost could changes from time to time. Please review before you confirm the transaction. Protocol Fee is paid to withdraw tokens to Etherum Mainnet.'
                                                                                }
                                                                            </TYPE.main>
                                                                        </AutoColumn>
                                                                    </AutoColumn>
                                                                )
                                                            }
                                                        />
                                                    </TYPE.main>
                                                )}
                                            </RowBetween>

                                            <RowBetween
                                                style={{ width: '100%' }}
                                            >
                                                <TYPE.main
                                                    fontSize={24}
                                                    fontWeight={600}
                                                >
                                                    {isDeposit
                                                        ? 'Less than 10 minutes'
                                                        : '7 DAYS'}
                                                </TYPE.main>
                                                <TYPE.main
                                                    fontSize={24}
                                                    fontWeight={600}
                                                >
                                                    {/* {loadingTransFee
                                                        ? 'Calculating'
                                                        : `${formatGasNum(
                                                              (!isDeposit
                                                                  ? 1
                                                                  : 0) +
                                                                  (transFeeInfo
                                                                      ? transFeeInfo.fee_num
                                                                      : -1),
                                                          )} ${
                                                              isDeposit
                                                                  ? 'ETH'
                                                                  : 'METIS'
                                                          }`} 
                                                          */}
                                                    {loadingTransFee
                                                        ? 'Calculating'
                                                        : `${
                                                              isDeposit
                                                                  ? formatGasNum(
                                                                        transFeeInfo
                                                                            ? transFeeInfo.fee_num
                                                                            : -1,
                                                                    )
                                                                  : formatGasNum(
                                                                        transFeeInfo
                                                                            ? transFeeInfo.servicesFee
                                                                            : -1,
                                                                    )
                                                          } ${
                                                              isDeposit
                                                                  ? 'ETH'
                                                                  : 'METIS'
                                                          }`}
                                                </TYPE.main>
                                            </RowBetween>
                                            {!isDeposit && (
                                                <RowBetween>
                                                    <TYPE.main
                                                        fontSize={12}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <DateIcon />
                                                        {isDeposit ? (
                                                            'Less than 10 minutes'
                                                        ) : (
                                                            <>
                                                                Before&nbsp;
                                                                {moment()
                                                                    .add(
                                                                        8,
                                                                        'days',
                                                                    )
                                                                    .format(
                                                                        'MMM DD, YYYY (UTC Z)',
                                                                    )}
                                                            </>
                                                        )}
                                                    </TYPE.main>
                                                    <TYPE.main fontSize={12}>
                                                        + GAS FEE
                                                    </TYPE.main>
                                                </RowBetween>
                                            )}
                                        </AutoColumn>
                                    </GasFeeWrapper>
                                </AutoColumn>
                            </>
                        )}
                        {isLidoToken ? (
                            <Row justify="center">
                                <Button
                                    className={'deposit'}
                                    onClick={handleLidoNext}
                                    disabled={connected && nextDisabled}
                                    showLoading
                                >
                                    {connected
                                        ? approvedLido
                                            ? 'Confirm Lido'
                                            : 'Approve'
                                        : 'Connect Wallet'}
                                </Button>
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Button
                                    className={'deposit'}
                                    onClick={handleNext}
                                    disabled={connected && nextDisabled}
                                    showLoading
                                >
                                    {connected
                                        ? approved
                                            ? 'Confirm'
                                            : 'Approve'
                                        : 'Connect Wallet'}
                                </Button>
                            </Row>
                        )}
                    </InputPanel>
                </div>
            </PageWrapper>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <AirdropImg
                    src={require('assets/images/activity/airdrop-gas.png')}
                    onClick={() => {
                        setDisclaimerModalOpen(true);
                    }}
                />
                <AirdropImgMobile
                    onClick={() => {
                        setDisclaimerModalOpen(true);
                    }}
                    src={require('assets/images/activity/airdrop-gas-mobile.png')}
                />
                <ThirdPartyList
                    isDeposit={isDeposit}
                    isMetis={currentToken === Token.metis}
                />
                {env === Env.mainnet && (
                    <Link
                        className={'historylink'}
                        to={'history'}
                    >{`View your transaction history >`}</Link>
                )}
            </div>
            <Alert
                isOpen={transAlertShow}
                duration={1000000}
                type="loading"
                onClose={() => {
                    // setConfirmBlockNumber(0);
                    // setTransBlockNumber(undefined);
                    setTransAlertShow(false);
                }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                text={
                    <>
                        <Row lineHeight="none">Pending</Row>
                        <Row lineHeight="none">
                            Blocks Confirms({confirmBlockNumber}/
                            {CONFIRM_BLOCKNUMBERS})
                        </Row>
                    </>
                }
            />
            <Modal
                isOpen={disclaimerModalOpen}
                onClose={() => {
                    setDisclaimerModalOpen(false);
                }}
            >
                <DisclaimerWrapper>
                    <TYPE.main
                        fontSize={16}
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Gas Fee Airdrop Rules
                    </TYPE.main>
                    <AutoColumn style={{ marginTop: '36px' }} gap="md">
                        <TYPE.main fontSize={16} fontWeight={600}>
                            • New wallets (no prior interaction with Andromeda
                            network)
                        </TYPE.main>
                        <TYPE.main fontSize={16} fontWeight={600}>
                            • Eligible tokens USDC, USDT, DAI, ETH, wBTC, Link,
                            AAVE, BUSD, Sushi (except Metis Token)
                        </TYPE.main>
                        <TYPE.main fontSize={16} fontWeight={600}>
                            • Min value amount $100 bridging into the Andromeda
                            Network
                        </TYPE.main>
                        <TYPE.main fontSize={16} fontWeight={600}>
                            • 0.01 Metis Token will be airdropped to the wallet
                            on Andromeda Network
                        </TYPE.main>
                        <TYPE.main fontSize={16} fontWeight={600}>
                            • You can use the airdropped Metis Token as the gas
                            fee to complete a few transactions on Andromeda
                            Network
                        </TYPE.main>
                    </AutoColumn>
                </DisclaimerWrapper>
            </Modal>
        </Wrapper>
    );
}
