import ABI from './abi.json';
import { ChainId } from '../../constants';

export const Config = {
    [ChainId.layer2Mainnet]: {
        address: '0xfac6B64b91fb112bbd1dCc48e7EA7310FbdF33a5',
        abi: ABI,
    },
    [ChainId.layer2Testnet]: {
        // address: '0x4200000000000000000000000000000000000010',
        address: '',
        abi: ABI,
    },
};
